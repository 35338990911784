import React from 'react'
import {Link, graphql} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'

const Blog = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Blog" description="The general musings of Mark Southard." />
      <h1 className="text-2xl mt-16">Blog</h1>
      <p className="text-xl leading-tight">
        Read about the experiences of a full-stack developer trying to succeed
        at blogging and building a successful side project.
      </p>
      <section className="mt-10">
        {posts.map(({node}, index) => (
          <div key={index}>
            <h2 className="text-xl mb-2">
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </h2>
            <p className="mt-0">
              {node.frontmatter.description || node.excerpt}
            </p>
          </div>
        ))}
      </section>
      {/*<Pagination pageContext={pageContext} />*/}
      <Footer message="← Back to home" />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
